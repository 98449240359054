.error404-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans";
  color: var(--blue);
  font-size: 1em;

  @import url("https://fonts.googleapis.com/css?family=Nunito+Sans");

  :root {
    --blue: #0e0620;
    --white: #fff;
    --green: #2ccf6d;
  }

  button {
    font-family: "Nunito Sans";
  }

  ul {
    list-style-type: none;
    padding-inline-start: 35px;
  }

  svg {
    width: 100%;
    visibility: hidden;
  }

  h1 {
    font-size: 7.5em;
    margin: 15px 0px;
    font-weight: bold;
  }

  h2 {
    font-weight: bold;
  }

  .hamburger-menu {
    position: absolute;
    top: 0;
    left: 0;
    padding: 35px;
    z-index: 2;

    & button {
      position: relative;
      width: 30px;
      height: 22px;
      border: none;
      background: none;
      padding: 0;
      cursor: pointer;

      & span {
        position: absolute;
        height: 3px;
        background: #000;
        width: 100%;
        left: 0px;
        top: 0px;
        transition: 0.1s ease-in;

        &:nth-child(2) {
          top: 9px;
        }

        &:nth-child(3) {
          top: 18px;
        }
      }
    }

    & [data-state="open"] {
      & span {
        &:first-child {
          transform: rotate(45deg);
          top: 10px;
        }

        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    body {
      display: block;
    }

    .container {
      margin-top: 70px;
      margin-bottom: 70px;
    }
  }
}

@primary-color: #45FFDA;