.layout-e {
    width: 100%;
    height: 100%;
  
    .layout-header {
      height: 60px;
      display: flex;
  
      .mid-e-container {
        flex: 1;
      }
  
      .paper-name {
        margin: 0 20px;
        text-align: center;
        line-height: 60px;
      }
  
      .timer-container {
        text-align: center;
        line-height: 60px;
        padding-left: 20px;
  
        .timer {
          padding: 0 4px 0 4px;
        }
      }
  
      .submit-container {
        padding-right: 20px;
        text-align: center;
        line-height: 60px;
      }
    }
  
    .exam-container {
      height: 100%;
      width: 100%;
      background-color: aquamarine;
  
      display: flex;
      align-items: center;
  
      .mid-e-container {
        flex: 1;
      }
  
      .paper-container {
        background-color: white;
        height: 100%;
        overflow-y: auto;
        // padding-bottom: 20px;
        padding: 0 20px;
  
        .topic {
          padding-bottom: 20px;
  
          .topic-title {
            font-size: 16px;
            padding-bottom: 10px;
            .order {
              padding-right: 5px;
            }
            .title {
              word-wrap: break-word;
              white-space: pre-wrap;
            }
          }
  
          .topic-options {
            display: flex;
            padding-bottom: 10px;
            .option {
              padding-right: 8px;
              .order {
                padding-right: 5px;
              }
            }
          }
  
          .topic-reply {
            padding-bottom: 10px;
  
            .reply-image {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
  
@primary-color: #45FFDA;